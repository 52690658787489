import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Col, Row  } from "antd";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
function Students() {
  const [activeGroup, setActiveGroup] = useState(null);
  const [clicked, setClicked] = useState(true)
  const [data ,setData] = useState([])
  const [DataAllStudents, ErrorAllStudents, LoadingAllStudents] = useAxios(
    process.env.REACT_APP_ADMIN_STUDENTS_TAB_API,
    "GET",
    "GET",
    ""
  );

  // Filter students by active group

  useEffect(()=>{
    const filteredStudents = activeGroup
    ? DataAllStudents?.data?.filter(student => student.group === activeGroup)
    : DataAllStudents?.data;
    setData(filteredStudents)
  },[activeGroup])

  const table = useTable("students", "", "", "", "", "", true, data);


  const handleGroupClick = (group) => {
    setActiveGroup(group);
    setClicked(false)
  };

  if(LoadingAllStudents){
    return <Loader/>
  }

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Students | Mr Ibrahim Abdel Masseh`} />

      <div className="mb-32 flex flex-col items-end gap-10">
        <h2 className="w-full text-4xl font-bold md:text-center">Students</h2>

        <div className="flex flex-col w-full gap-10 md:justify-evenly items-start">
          <Row gutter={[16, 16]} justify="center" align="middle" style={{ marginTop: '20px' }}>
            {['A', 'B' , 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].map(group => (
              <Col key={group}>
                <button
                  onClick={() => handleGroupClick(`Group ${group}`)}
                  disabled={activeGroup === `Group ${group}`}
                  className={`rounded-xl border-2 border-secondary bg-secondary px-4 py-3 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${activeGroup === `Group ${group}` ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Group {group}
                </button>
              </Col>
            ))}
          </Row>
          <Link
            to="/admin/students/add-student"
          >
          <button  className="mr-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90" disabled={clicked}>
        
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Student
         
            
          </button>
          </Link>
        </div>
      </div>

      <div className="h-fit w-full">{table}</div>
    </section>
  );
}

export default Students;
