import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";

const AdminCorrection = () => {
  const table = useTable("corrections", "", "");

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Correction | Mr Ibrahim Abdel Masseh"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center mb-32">
        Correction
      </h2>{" "}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminCorrection;
