import axios from "axios";
import { useMemo } from "react";
import { Navigate, Outlet, useLocation, redirect } from "react-router-dom";

function ProtectedAdminRoutes({ isAllowed, redirectPath = "/", children }) {
  const location = useLocation();

  // useMemo(() => {
  //   // set axios
  //   // axios.defaults.baseURL = allUrl.apiUrl;
  //   axios.defaults.headers.common["Authorization"] =
  //     "Bearer " + JSON.parse(localStorage.userData).student_token;
  // }, []);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  } else {
    return children ? children : <Outlet />;
  }
}

export default ProtectedAdminRoutes;
