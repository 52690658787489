import VideoStatusUsingAPI from "./Status";

export default function VdoCiperrPlayer({
  videoId,
  platform,
  history_id,
  videoRef,
  setVideoRef,
  videoContainerRef,
}) {
  return (
    <div className={`h-full ${platform !== "vdocipher" && "hidden"}`}>
      <VideoStatusUsingAPI
        videoId={videoId}
        history_id={history_id}
        videoRef={videoRef}
        setVideoRef={setVideoRef}
        isAPIReady={true}
      />
      <div
        className={`vdo-container h-full w-full`}
        ref={videoContainerRef}
      ></div>
    </div>
  );
}
