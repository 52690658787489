import HelmetTags from "../../../../MainComponents/HelmetTags";
import UpdateAdminImage from "./UpdateAdminImage";
import AdminChangePassword from "./AdminChangePassword";

const AdminProfile = () => {
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Profile | Mr.Mohamed Salama"></HelmetTags>{" "}
      <h2 className="mb-7 text-start sm:text-center text-4xl font-bold">
        Profile
      </h2>
      <UpdateAdminImage />
      <AdminChangePassword />
    </section>
  );
};

export default AdminProfile;
