const RightQuestionsSidebar = ({ state, dispatch }) => {
  return (
    <div>
      <div
        className={` right-questions-sidebar-collapse flex items-center justify-center overflow-y-auto px-[20px] transition-all duration-300 ease-in-out md:w-full  mb-10 `}
      >
        <div className={`myfixed mytoggle-fixed h-fit w-full  `}>
          <div className="myquestions-container-relative h-fit w-full mt-12">
            <div className="myquestions-wrapper mytoggle-QuestionsWrapper h-fit w-full flex items-center">
              <div className="myactual-questions-container h-fit  w-full !flex items-center flex-row">
                {state.questions.map((question, i) => (
                  <span
                    key={i}
                    className={`${
                      question.is_selected
                        ? "selected-popup-question"
                        : "myquestion"
                    }`}
                    onClick={() =>
                      dispatch({ type: "setCurrentQuestionIndex", payload: i })
                    }
                  >
                    {i + 1}
                  </span>
                ))}
              </div>

              {/* TODO: ADD RESULT CONTAINER */}
              {/* <div
                // ref={resultContainer}
                className="result-container flex h-fit w-full flex-col items-center  gap-[16px]"
              ></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightQuestionsSidebar;
