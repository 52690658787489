import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
function AddEditPost({ edit, setReFetch, reFetch }) {
  const [content, setContent] = useState("");
  const [stage, setStage] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const { postID } = useParams();

  const [submitContent, setSubmitContent] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed
  useEffect(() => {
    if (content !== "") {
      setErrors("");
    } else if (stage !== "") {
      setErrors("");
    }
  }, [content, stage]);

  //!---add edit content request
  let api = edit
    ? process.env.REACT_APP_EDIT_DASHBOARD_POST_API
    : process.env.REACT_APP_ADD_DASHBOARD_POST_API;
  const [addEditContentSuccess, addEditContentErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitContent.flag,
    submitContent.dependency,
    submitContent.data,
    true
  );

  //!---get content data request

  const [ContentDataSuccess, ContentErrors] = useAxios(
    `${process.env.REACT_APP_DASHBOARD_POST_INFO_API}${postID}`,
    "GET",
    edit
  );
  useEffect(() => {
    if (ContentDataSuccess) {
      setStage(ContentDataSuccess.data?.stage);
      setContent(ContentDataSuccess.data?.content);
    }
  }, [ContentDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content === "") {
      setErrors("content");
      toast.error("Please Add Content");
    } else if (stage === "") {
      setErrors("stage");
      toast.error("Please Add Stage");
    } else {
      const data = edit
        ? {
            content: content,
            stage: stage,
            id: postID,
          }
        : {
            content: content,
            stage: stage,
          };

      setSubmitContent({
        flag: "addEditContentRequest",
        dependency: !submitContent.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditContentSuccess) {
      setContent("");
      setStage("");

      setErrors("");
      edit ? navigate(-1) : setReFetch(!reFetch);
    }
  }, [addEditContentSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light pt-16`}
    >
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container  flex flex-col items-center"
      >
        <h2 className={` text-center text-3xl mb-14`}>
          {edit
            ? "Please fill out the information to edit the post."
            : "Please fill out the information to add the post."}
        </h2>
        {/**
         * //!------content-----
         */}
        <div className=" content mb-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px]  sm:w-[300px]">
          <h2 className="mb-3  font-semibold">Post</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
          />
          {errors === "content" && (
            <p className="w-full  text-[12px] text-red-900  ">
              {" "}
              Please fill out this field.{" "}
            </p>
          )}
          {
            //!-------server errors -----

            addEditContentErrors &&
              addEditContentErrors?.response?.data?.errors?.content && (
                <p className="w-full  text-[12px] text-red-900  ">
                  {addEditContentErrors?.response?.data?.errors?.content[0]}
                </p>
              )
          }
        </div>

        {/** //!---Stage degree
         *
         *       */}
        {/* <div className="w-full flex  gap-4 md:flex-col">
          <div className="flex w-1/2 flex-col  items-center md:order-2 md:w-full">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="Type"
            >
              Type
            </label>
            <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="ig"
                  value="0"
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                />
                <label className="cursor-pointer text-start" htmlFor="ig">
                  IG
                </label>
              </div>

              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="national"
                  name="type"
                  value="1"
                  onChange={(e) => setType(e.target.value)}
                />
                <label className="cursor-pointer text-start" htmlFor="national">
                  National
                </label>
              </div>
            </div>
          </div>
          {type && (
            <div className="w-1/2 md:w-full">
              <label className="w-full truncate  font-semibold" htmlFor="stage">
                Class
              </label>
              {type === "0" ? (
                <select name="stage" id="stage" value={stage}>
                  <option value="" disabled selected>
                    Select a stage
                  </option>

                  <option value="9">Nine Year</option>
                  <option value="10">Ten Year</option>
                </select>
              ) : (
                <>
                  <select
                    name="stage"
                    id="stage"
                    onChange={(e) => setStage(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select a stage
                    </option>

                    <option value="22">Second preparatory</option>
                    <option value="33">Third preparatory </option>
                    <option value="1">First Secondary</option>
                    <option value="2">Second Secondary</option>
                    <option value="3">Third Secondary</option>
                  </select>
                </>
              )}
            </div>
          )}
        </div> */}

        <button
          disabled={submitLoading && submitContent.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitContent.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit post" : "Add post"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditPost;
