import { useEffect, useState } from "react";

const useCountDown = (date = "") => {
  const targetDate = new Date(date).getTime();
  const [countdown, setCountdown] = useState({
    days: "01",
    hours: "01",
    minutes: "01",
    seconds: "01",
  });

  const { days, hours, minutes, seconds } = countdown;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance > 0) {
        const days = String(
          Math.floor(distance / (1000 * 60 * 60 * 24))
        ).padStart(2, "0");
        const hours = String(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        ).padStart(2, "0");
        const minutes = String(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0");
        const seconds = String(
          Math.floor((distance % (1000 * 60)) / 1000)
        ).padStart(2, "0");

        setCountdown({ days, hours, minutes, seconds });
      } else {
        // Countdown is over, do something
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { days, hours, minutes, seconds };
};

export default useCountDown;
