import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import useCountDown from "./Hooks/useCountDown";

function SingleCourse({
  lecture,
  admin,
  exam,
  reFetch,
  setReFetch,
  notSigned,
}) {
  const { days, hours, minutes, seconds } = useCountDown(lecture.expire_at);
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Lecture -------
  //!--------- delete Lecture -------
  const apiDelete = exam
    ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
    : process.env.REACT_APP_ADMIN_DELETE_LECTURE_API;

  const [deleteLectureSuccess, deleteLectureErrors, deleteloading] = useAxios(
    apiDelete,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteLecture(id) {
    setSubmitDelete({
      flag: "deleteLecture",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteLectureSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteLectureSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course min-h-[400px] w-[350px] bg-white shadow-3xl rounded-3xl sm:w-[300px] "
    >
      <div className="img relative h-[170px] w-full cursor-pointer overflow-hidden">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `${
                        exam
                          ? `/admin/exams/${lecture?.key}`
                          : `/admin/courses/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/exams/${lecture?.key}`
                          : `/lectures/sessions/${lecture?.key}`
                      }`
              }`
            )
          }
          className="h-full w-full object-cover  duration-300 hover:scale-125"
          src={lecture?.img}
          alt={lecture?.title}
        />
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_show_creator")
          )) &&
          admin &&
          !exam && (
            <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold ">
              {lecture?.created_by && `By ${lecture?.created_by} `}
            </div>
          )}
      </div>
      <div className="course-details p-8 mt-4 flex flex-col items-center text-center">
        {/* <div className="stage-wrapper mb-10 flex h-10 w-fit items-center justify-center gap-3  rounded-xl bg-light px-3  font-semibold">
          <FontAwesomeIcon className="h-5 w-5 " icon={faUserGraduate} />
        </div> */}
        {/* <p className="stage pointer-events-none  mb-10 text-xl font-semibold">
          {lecture?.stage === 3 ? "الصف الثالث" : lecture?.stage === 2 ? "الصف الثاني" : "الصف الاول"}
        </p> */}
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `${
                        exam
                          ? `/admin/exams/${lecture?.key}`
                          : `/admin/courses/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/exams/${lecture?.key}`
                          : `/lectures/sessions/${lecture?.key}`
                      }`
              }`
            )
          }
          className={`title mb-7 w-fit cursor-pointer text-secondary ${
            admin ? "text-end" : "text-center"
          }   text-2xl  font-bold`}
        >
          {lecture?.title?.substring(0, 33)}
        </h3>
        <p
          dir="ltr"
          className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70"
        >
          {lecture?.description?.substring(0, 70)}...
        </p>

        {!admin && !exam && (
          <>
            {lecture.is_buy === 1 && (
              <div className="w-full font-bold py-2 border-t-2 border-b-2 border-secondary rounded-md mt-4">
                <p className="mb-2">Expire After </p>
                <p className="text-center text-2xl">
                  {days} Days | {hours} Hours | {minutes} Miniutes | {seconds}
                  Seconds
                </p>
              </div>
            )}
          </>
        )}

        <div
          className={`visibility-cta mt-5 flex w-full   ${
            admin ? "justify-between" : "justify-center"
          } items-center pb-4 pt-12`}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture")
            )) &&
            admin && (
              <div className="visibility pointer-events-none flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                {lecture?.visibility === 0 ? "Hidden" : "Visible"}
              </div>
            )}
          {admin ? (
            <div className="admin-options flex w-full justify-end gap-2">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_delete" : "lecture_delete"}`
                  )
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="red"
                  cancelText="Cancel"
                  color="#b1e8f4"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteLecture(lecture?.key)}
                  title={
                    exam
                      ? "Do you really want to delete the Exam?"
                      : "Do you really want to delete the lecture?"
                  }
                >
                  <button className=" rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}

              {exam && (
                <Link
                  to={`/admin/exams/all-students/${lecture?.key}`}
                  className="rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                >
                  Students
                </Link>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_edit" : "lecture_edit"}`
                  )
                )) &&
                admin && (
                  <Link
                    to={`${
                      exam
                        ? `/admin/exams/edit-exam/${lecture?.key}`
                        : `/admin/courses/edit-lecture/${lecture?.key}`
                    }`}
                    className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    Edit
                  </Link>
                )}
            </div>
          ) : (
            <div className="flex justify-between w-full items-center flex-col gap-5">
              <div className="stage-wrapper flex h-10 w-fit items-center justify-center gap-1  rounded-xl bg-light px-3  font-semibold">
                <p className="stage flex flex-row-reverse gap-1 text-xl font-semibold">
                  {lecture?.price === 0 || lecture?.points === 0 ? (
                    <span>Free</span>
                  ) : (
                    <>
                      {" "}
                      <span>EGP</span>
                      <span>{lecture?.price} </span>
                    </>
                  )}
                </p>
              </div>
              <Link
                to={
                  notSigned
                    ? "/signin"
                    : `${
                        exam
                          ? `/exams/${lecture?.key}`
                          : `/lectures/sessions/${lecture?.key}`
                      }`
                }
                className="buttonS1"
              >
                {lecture.is_buy === 1
                  ? `${exam ? "View Exam" : "View Lecture"}`
                  : "Enroll Now"}
              </Link>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default SingleCourse;
