import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
export const BunnyVideo = ({ src }) => {
  const [minWatchTime, setMinWatchTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [pausedTime, setPausedTime] = useState(0);
  let videoRef = null;

  useEffect(() => {
    // Cleanup function
    return () => {
      // Reset the video and states when unmounting
      if (videoRef) {
        videoRef.pause();
        setMinWatchTime(0);
        setStartTime(null);
        setPausedTime(0);
      }
    };
  }, []);

  return (
    <iframe
      src={src}
      width="100%"
      height="100%"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      id="courseVideo"
    ></iframe>
  );
};
